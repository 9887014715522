import { type ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  children?: ReactNode
  isShow: boolean
  transitionSpeed?: number
  className?: string
}

export const TransitionFade = ({ children, isShow, transitionSpeed = 0.3, className }: Props) => {
  return (
    <AnimatePresence mode="wait">
      {isShow && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: transitionSpeed,
          }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
